/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In New Jersey, loud noise is part of the fabric of life. Cities like Paterson and Newark are famously noisy industrial centers, while thousands of fans create a cacophony at MetLife Stadium on a weekly basis. And any fan of “The Boss” will tell you that high volume is a key part of Bruce Springsteen’s appeal."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All of these instances of loud noise exposure can cause hearing damage over long periods of time. But it doesn’t have to be that way! In a state that loves music and socializing, why let your hearing gradually fade away? By taking action and seeking expert help, any New Jerseyan can enjoy crystal clear hearing for longer."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "its-surprisingly-easy-to-damage-your-hearing-in-new-jersey",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#its-surprisingly-easy-to-damage-your-hearing-in-new-jersey",
    "aria-label": "its surprisingly easy to damage your hearing in new jersey permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "It's surprisingly easy to damage your hearing in New Jersey"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most people are unaware that activities they take part in on a regular basis could be damaging their hearing. For many people, work is a major factor for hearing loss, and there are plenty of noisy occupations in the Garden State."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "From driving buses for the New Jersey Transit network, to manning the ground teams at Newark Airport, your work could be slowly leading you to hearing loss. But leisure and recreation can be just as damaging. When 82,500 people cram into the MetLife Stadium to cheer on the Giants or Jets, noise levels can top 100 decibels (well above the generally recognized safe range for sound). Even getting away from it all for some hunting in the Pine Barrens can be hazardous."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rifles can create 140 decibels of noise. Many hunters forget their protective equipment, even though noise levels like that can cause damage to your hearing instantaneously. But you don’t need to be exposed to firearms or stadium roars to experience environmental hearing damage. Music and the constant sound of the state highways can also take a toll on your hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you’re exposed to loud noise for periods of 30 years or more, it’s easy to see why so many New Jerseyans report diminished hearing by middle age."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-stats-behind-hearing-loss-in-new-jersey",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-stats-behind-hearing-loss-in-new-jersey",
    "aria-label": "the stats behind hearing loss in new jersey permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The stats behind hearing loss in New Jersey"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are a New Jersey resident with hearing loss, you’re certainly not alone. According to the state’s Department of Human Services, around 850,000 residents of the Garden State are known to have some degree of hearing loss. And it’s not just seniors who are struggling to hear. Anyone can be affected, and the problem is arguably worse for those under the age of 70."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "That’s because only about 16% of those under 70 who could benefit from hearing aids use them. That’s a substantial amount of people who simply accept a lower quality of life instead of taking advantage of the latest cutting edge technology to help them socialize, work, and spend precious time with loved ones."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-hearcom-to-find-the-perfect-hearing-aid-in-new-jersey",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-hearcom-to-find-the-perfect-hearing-aid-in-new-jersey",
    "aria-label": "contact hearcom to find the perfect hearing aid in new jersey permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact hear.com to find the perfect hearing aid in New Jersey"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we have a mission: to ensure that more Americans get the help they need to improve and protect their hearing. To do so, we’ve set up a nationwide network of Partner Providers, which includes over 80 hearing specialists and audiologists in the state of New Jersey. These Partner Providers are trained to assist anyone with hearing loss, but they don’t work alone."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Before you visit an audiologist or hearing specialist, you’ll have a free, personal phone consultation with one of our hearing aid experts who will tell you all about the latest technology available and suggest some devices that might be suitable for your lifestyle and needs. We know the hearing aid industry inside and out, so we’re in a great position to help you find your perfect device."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-on-the-path-to-better-hearing-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-on-the-path-to-better-hearing-today",
    "aria-label": "get on the path to better hearing today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get on the path to better hearing today"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you live in New Jersey and want to improve your hearing with the latest hearing aid technology, getting started couldn’t be easier! Just fill out our online questionnaire and we’ll arrange a free, personal phone consultation with one of our hearing aid experts."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During this consultation, we’ll schedule an appointment with your local Partner Provider for a ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " and hearing aid fitting. After that, you’ll enjoy a 45 day risk-free trial period to wear, test, and fall in love with your new devices and improved quality of life. If you’re not completely satisfied, simply return them after the trial and get every penny back (no restocking fees)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So what are you waiting for, New Jersey? Contact us today! We can’t wait to help solve your hearing loss."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
